import { useEffect, useRef, useContext, useState } from 'react';
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { MainContext } from '../../App';
import AskSpecialistModal  from "../modals/askSpecialistModal";
import IsAuthenticated from "../auth/IsAuthenticated";

import specialist from "../../assets/images/SpecialistHeroNewCropped.png";
import SpecialistHeroNew from '../../assets/images/SpecialistHeroNew.png';
import ManyHandsGreen from '../../assets/images/ManyHandsGreen.png';
import ChatHero from '../../assets/images/ChatHero.png';
import PhoneVideoCall  from '../../assets/images/PhoneVideoCall.png';
import SearchHero  from '../../assets/images/SearchHero.png';
import CommIconsHero  from '../../assets/images/CommIconsHero.png';
import PauseIcon  from '../../assets/images/PauseIcon.svg';
import CardIcon  from '../../assets/images/CardIcon.svg';
import CheckIcon  from '../../assets/images/CheckIcon.svg';
import LockCardIcon  from '../../assets/images/LockCardIcon.svg';
import PersonIcon  from '../../assets/images/PersonIcon.svg';
import SplitIcon  from '../../assets/images/SplitIcon.svg';
import NoteHero  from '../../assets/images/NoteHero.png';
import CertifiedHero  from '../../assets/images/CertifiedHero.png';
import CASHBACKTag  from '../../assets/images/CASHBACKTag.png';
import CashbackHero  from '../../assets/images/CashbackHero.png';
import ForYouHero  from '../../assets/images/ForYouHero.png';
import VerifiedProPromo  from '../../assets/images/VerifiedProPromo.png';
import ReferHero  from '../../assets/images/ReferHero.png';

import DraggableScrollArea from 'draggable-scrollarea';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

const images = importAll(require.context('../../assets/images', false, /\.(webp|png|jpe?g|svg)$/));

const ManyHandsInfo = () => {

    const slideRef = useRef(null);
    const helpSliderRef = useRef(null);
    const difSliderRef = useRef(null);
    const {mainModalTrigger} = useContext(MainContext);
    const [isAuthed, setIsAuthed] = useState(false);


    useEffect(() => {
        const draggableDiv = new DraggableScrollArea(slideRef.current);
        const helpDraggableDiv = new DraggableScrollArea(helpSliderRef.current);
        const difDraggableDiv = new DraggableScrollArea(difSliderRef.current);

        setIsAuthed(IsAuthenticated());


    },[]);

    const showSpecialistModal = (e) => {
        e.preventDefault();
        mainModalTrigger(<AskSpecialistModal callback={mainModalTrigger}/>, "ask-specialist-modal", (
            <div className="support-div-block bg-white d-flex ask-specialist-modal-header">
              <h2>Book a session with a ManyHands Specialist.</h2>
              <img src={specialist} loading="lazy"  alt=""/>
            </div>));
    };


    return (
        <>
          {/* Section 1*/}
          <div className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">We're here to help.&nbsp;<span className="grey">However and whenever you need it.</span>
              </h2>
            </div>
          </div>

          <div className="container-4 _20vw-left-only margin-bottom">
            <div ref={helpSliderRef} className="flex-horizontal slider-container">
              <div className="_20vw-div">
              </div>
              <Link to="/support" className="large-box w-inline-block">
                <div className="slide-text-container">
                  <div className="grey-mini-text">ASK&nbsp;A&nbsp;MANYHANDS&nbsp;SPECIALIST</div>
                  <div className="title-text">Have questions you would like answered by a Specialist?</div>
                </div>
                <img src={`${images["SpecialistHeroNew.png"]}`} loading="lazy"
                     sizes="(max-width: 479px) 97vw, (max-width: 767px) 92vw, 480.0000305175781px"
                     srcset={`${images["SpecialistHeroNew-p-500.png"]} 500w, ${images["SpecialistHeroNew-p-800.png"]} 800w, ${images["SpecialistHeroNew.png"]} 1080w`} alt="" className="slide-bg"/>
              </Link>
              <Link to="/services?service_pros=true" className="large-box w-inline-block">
                <div className="slide-text-container">
                  <div className="grey-mini-text">ASK&nbsp;A Verified PRO</div>
                  <div className="title-text">Have a question about a service pack? Just ask a Service Pro.</div>
                </div>
                <img src={ChatHero} loading="lazy" sizes="(max-width: 479px) 97vw, (max-width: 767px) 92vw, 480.0000305175781px"
                     srcset={`${images["ChatHero-p-500.png"]} 500w, ${images["ChatHero-p-800.png"]} 800w, ${images["ChatHero-p-1080.png"]} 1080w, ${images["ChatHero-p-1600.png"]} 1600w, ${images["ChatHero.png"]} 1920w `} alt="" className="slide-bg"/>
              </Link>
              <Link to="#" onClick={showSpecialistModal} className="large-box w-inline-block">
                <div className="slide-text-container">
                  <div className="grey-mini-text text-green">Available on MANYHANDS+</div>
                  <div className="title-text white-text">Shop services with a Specialist over video.</div>
                  <div className="div-block">
                    <div className="body-text white-text right-padding">Choose the right service pack for your needs in a guided, one-way video session.</div>
                  </div>
                </div>
                <img src={PhoneVideoCall} loading="lazy" sizes="(max-width: 479px) 97vw, (max-width: 767px) 92vw, 480.0000305175781px"
                     srcset={`${images["PhoneVideoCall-p-1080_1-p-500.png"]} 500w, ${images["PhoneVideoCall-p-1080_1-p-800.png"]} 800w, ${images["PhoneVideoCall-p-1080_1.png"]} 1080w`} alt="" className="slide-bg"/>
              </Link>
              <div className="large-box">
                <HashLink to="/support#FAQ" className="slide-box small top w-inline-block clickable">
                  <div className="slide-text-container">
                    <div className="grey-mini-text">
                    </div>
                    <div className="title-text-24px">Visit our FAQs for self service help 24/7, 365.</div>
                  </div>
                  <img src={SearchHero} loading="lazy" sizes="(max-width: 479px) 100vw, 480.0000305175781px"
                       srcset={`${images["SearchHero-p-500.png"]} 500w, ${images["SearchHero-p-800.png"]} 800w, ${images["SearchHero-p-1080.png"]} 1080w, ${images["SearchHero-p-1600.png"]} 1600w, ${images["SearchHero.png"]} 1920w`} alt="" className="slide-bg"/>
                </HashLink>
                <Link to="/support" className="slide-box small top w-inline-block clickable">
                  <div className="slide-text-container">
                    <div className="grey-mini-text">
                    </div>
                    <div className="title-text-24px">Contact us anytime via WhatsApp, WebChat, Email or Phone.</div>
                  </div>
                  <img src={CommIconsHero} loading="lazy" sizes="(max-width: 479px) 100vw, 480.0000305175781px"
                       srcset={`${images["CommIconsHero-p-500.png"]} 500w, ${images["CommIconsHero-p-800.png"]} 800w, ${images["CommIconsHero-p-1080.png"]} 1080w, ${images["CommIconsHero-p-1600.png"]} 1600w, ${images["CommIconsHero.png"]} 1920w`} alt="" className="slide-bg"/>
                </Link>
              </div>
            </div>
          </div>


          {/* Section 2*/}
          <div className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">The ManyHands difference.&nbsp;<span className="grey">Even more reasons to buy services from us.</span>
              </h2>
            </div>
          </div>
          <div ref={difSliderRef} className="slider-container">
            <div className="_20vw-div">
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={PauseIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">Enjoy the flexibility of <span className="pink">pausing or cancelling </span>at any time <span className="pink">at no cost.</span>
                </div>
              </div>
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={CardIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">Pay for a service pack in full or <span className="blue">pay over time. </span>Your choice.</div>
              </div>
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={CheckIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">
                  <span className="aqua">Quality guaranteed. </span>We do <span className="aqua">24 quality checks </span>on each service pack.</div>
              </div>
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={LockCardIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">
                  <span className="bright-green">Buy</span> any service <span className="bright-green">securely</span> with our <span className="bright-green">Service Cover.</span>
                </div>
              </div>
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={PersonIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">Create an account for a <span className="drk-green">personalised </span>experience just for you.</div>
              </div>
            </div>
            <div className="slide-box _240px margin-right">
              <div className="slide-text-container">
                <img src={SplitIcon} loading="lazy" alt="" className="slide-bg-copy"/>
                <div className="title-text-24px">Easily buy <span className="bright-blue">personal</span> and <span className="bright-blue">business</span> services with one account.<span className="bright-blue">
                                                                                                                                                                                      </span>
                </div>
              </div>
            </div>
          </div>

          {/* Section 3*/}
          <div className="container-4 _20vw-left-only">
            <div className="hero-div">
              <h2 className="title-text less-bottom-space left-align">The ManyHands experience.&nbsp;<span className="grey">Get the most out of your ManyHands account.</span>
              </h2>
            </div>
          </div>
          <div ref={slideRef}className="slider-container small-bottom-space">
            <div className="_20vw-div">
            </div>
            <Link to="https://blog.manyhands.co.za/post/why-manyhands-service-delivery-is-revolutionary" target="_blank" className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container">
                <div className="grey-mini-text">THE&nbsp;MANYHANDS DIFFERENCE</div>
                <div className="title-text">Learn why our service delivery is unlike anything you've ever used before.</div>
              </div>
              <img src={NoteHero} loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px"
                   srcset={`${images["NoteHero-p-500.png"]} 500w, ${images["NoteHero-p-800.png"]} 800w, ${images["NoteHero-p-1080.png"]} 1080w, ${images["NoteHero-p-1600.png"]} 1600w, ${images["NoteHero.png"]} 1920w`} alt="" className="slide-bg"/>
            </Link>
            <Link to="/many-updates?post=12May2022" className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container">
                <div className="grey-mini-text">CERTIFIED&nbsp;PARTNERS</div>
                <div className="title-text">Our service&nbsp;packs are delivered by certified partners in the tools you already use.</div>
              </div>
              <img src={CertifiedHero} loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px"
                   srcset={`${images["CertifiedHero-p-500.png"]} 500w, ${images["CertifiedHero-p-800.png"]} 800w, ${images["CertifiedHero-p-1080.png"]} 1080w, ${images["CertifiedHero-p-1600.png"]} 1600w, ${images["CertifiedHero.png"]} 1920w`} alt="" className="slide-bg"/>
            </Link>
            <Link to="https://blog.manyhands.co.za/post/say-hello-to-3--cash-back" target="_blank"className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container w-clearfix">
                <div className="white-mini-text-new">ALWAYS&nbsp;REWARDING</div>
                <div className="title-text white-text">Earn 3% cash back every time you buy a service pack.</div>
                <div className="div-block-78">
                  <img src={CASHBACKTag} loading="lazy" width="92" sizes="(max-width: 479px) 100vw, 91.99652862548828px" alt=""
                       srcset={`${images["CASHBACKTag-p-500.png"]} 500w, ${images["CASHBACKTag.png"]} 536w`}/>
                </div>
              </div>
              <img src={CashbackHero} loading="lazy" width="960" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px" alt=""
                   srcset={`${images["CashbackHero-p-500.png"]} 500w, ${images["CashbackHero-p-800.png"]} 800w, ${images["CashbackHero-p-1080.png"]} 1080w, ${images["CashbackHero-p-1600.png"]} 1600w, ${images["CashbackHero.png"]} 1920w`} className="slide-bg"/>
            </Link>
            <Link to={isAuthed ?  "/pricing" : "/sign-up"} className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container">
                <div className="grey-mini-text">JUST&nbsp;FOR&nbsp;YOU</div>
                <div className="title-text">Complete your profile for recommendations just for you.</div>
              </div>
              <img src={ForYouHero} loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px"
                   srcset={`${images["ForYouHero-p-500.png"]} 500w, ${images["ForYouHero-p-800.png"]} 800w, ${images["ForYouHero-p-1080.png"]} 1080w, ${images["ForYouHero-p-1600.png"]} 1600w, ${images["ForYouHero.png"]} 1920w`} alt="" className="slide-bg"/>
            </Link>
            <Link to="/manyhandsplus" className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container">
                <div className="grey-mini-text">LIMITED TIME OFFER FOR CLIENTS</div>
                <div className="title-text">
                  <span className="text-span-15">Get 6 months free</span> when buying a <span className="text-span-16">ManyHands+ yearly plan</span> by 31st January 2025.</div>
              </div>
              <img src={ManyHandsGreen} loading="lazy" width="960" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px" alt=""
                   srcset={`${images["ManyHandsGreen-p-500.png"]} 500w, ${images["ManyHandsGreen-p-800.png"]} 800w, ${images["ManyHandsGreen-p-1080.png"]} 1080w, ${images["ManyHandsGreen-p-1600.png"]} 1600w, ${images["ManyHandsGreen.png"]} 1920w`} className="slide-bg"/>
            </Link>
            <Link to="/verified-pro" className="slide-box _480px margin-right w-inline-block">
              <div className="slide-text-container">
                <div className="grey-mini-text">
                  <span className="fa-brands">LIMITED TIME OFFER SERVICE PROS</span>
                </div>
                <div className="title-text">
                  <span className="text-span-17">Get 4 months free </span>when you upgrade to a <span className="text-span-18">Verified Pro yearly plan</span> by 31st January 2025.</div>
              </div>
              <img src={VerifiedProPromo} loading="lazy" width="960" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px" alt=""
                   srcset={`${images["VerifiedProPromo-p-500.png"]} 500w, ${images["VerifiedProPromo-p-800.png"]} 800w, ${images["VerifiedProPromo-p-1080.png"]} 1080w, ${images["VerifiedProPromo-p-1600.png"]} 1600w, ${images["VerifiedProPromo.png"]} 1920w`} className="slide-bg"/>
            </Link>
            {/* <Link to="#" className="slide-box _480px margin-right w-inline-block"> */}
            {/*   <div className="slide-text-container"> */}
            {/*     <div className="grey-mini-text">REFER&nbsp;&amp;&nbsp;EARN</div> */}
            {/*     <div className="title-text">Invite a business and get 5% of their spend, earnings or fees. Forever.</div> */}
            {/*   </div> */}
            {/*   <img src={ReferHero} loading="lazy" sizes="(max-width: 479px) 95vw, (max-width: 767px) 92vw, 480.0000305175781px" */}
            {/*        srcset={`${images["ReferHero-p-500.png"]} 500w, ${images["ReferHero-p-800.png"]} 800w, ${images["ReferHero-p-1080.png"]} 1080w, ${images["ReferHero-p-1600.png"]} 1600w, ${images["ReferHero.png"]} 1920w`}  alt="" className="slide-bg"/> */}
            {/* </Link> */}
          </div>


        </>
    );
};

export default ManyHandsInfo;
